<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Oleic acid,
        <chemical-latex content="C18H34O2," />
        is the major fatty acid in olive oils. According to the rules laid down by the International
        Olive Oil Council (IOOC), its concentration must range from 55% to 83% of total fatty acids.
        The human body can use fatty acids as a fuel in a series of reactions that ultimately
        produces carbon dioxide and water, along with the energy storage molecule ATP.
      </p>

      <p class="mb-2">
        a) Write the balanced equation for the combustion of <b>liquid</b> oleic acid under standard
        conditions. Be sure to indicate the phase of each species. Be sure to use all integers.
        Scale up coefficients to avoid half-integers if necessary.
      </p>

      <chemical-notation-input
        v-model="inputs.combustionEqn"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">b) Is this reaction endothermic or exothermic?</p>

      <v-radio-group v-model="inputs.thermicity" :disabled="!allowEditing" class="ml-6 mb-0">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block mb-1" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        c) Determine the molar heat of combustion of oleic acid, given that burning
        <number-value :value="molAcid" unit="\text{mmol}" />
        of oleic acid produces
        <latex-number :number="heatProduced.toFixed(2)" unit="\text{kJ}" />
        of heat.
      </p>

      <calculation-input
        v-model="inputs.Hrxn"
        class="mb-5"
        prepend-text="$\Delta\text{H}^\circ_\text{rxn}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Write the balanced chemical equation for the formation of oleic acid under standard
        conditions. Be sure to indicate the phase of each species. Be sure to use all integers.
        Scale up coefficients to avoid half-integers if necessary.
      </p>

      <chemical-notation-input
        v-model="inputs.formationEqn"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <v-radio-group v-model="inputs.work" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        e) Calculate the heat of formation of oleic acid given that the heat of formation for
        <chemical-latex content="CO2(g)" />
        is
        <stemble-latex content="$-393.5\,\text{kJ/mol}$" />
        while that for
        <chemical-latex content="H2O(l)" />
        is
        <stemble-latex content="$-285.83\,\text{kJ/mol.}$" />
        You will need to use your answers for parts a) and c) to solve this question.
      </p>

      <calculation-input
        v-model="inputs.Hformation"
        class="mb-5"
        prepend-text="$\Delta\text{H}^\circ_\text{f}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question456',
  components: {
    LatexNumber,
    NumberValue,
    ChemicalNotationInput,
    ChemicalReactionInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        combustionEqn: null,
        thermicity: null,
        Hrxn: null,
        formationEqn: null,
        Hformation: null,
      },
      options1: [
        {expression: '$\\text{Exothermic}$', value: 'exothermic'},
        {expression: '$\\text{Endothermic}$', value: 'endothermic'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
    };
  },
  computed: {
    molAcid() {
      return this.taskState.getValueBySymbol('molAcid').content;
    },
    heatProduced() {
      return this.molAcid.toFloat() * 11.183;
    },
  },
};
</script>
